import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Non Profit`}</p>
    <p>{`ERPNext for Non Profits
ERPNext Non Profit module helps you manage all your members, donors, projects and much more.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Members and Volunteers
Maintain data of all your members, their memberships and terms, also collect payments online.
Donors
Manage a database of your donors and their details. Also accept payments online via the portal.
Grants
Keep a track of all Grant applications and track expenses with projects.
Project Management
Manage all your projects with tasks, assignments and reminders and tracking expenses and billing too.
Financial Accounting
Track all things financial, including billings and payments with the ERPNext accounts module.
Stock and Inventory
ERPNext stock module will help you manage inventory and procurement across warehouses.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      